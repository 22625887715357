<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" title="盘点损益统计明细" :close-on-click-modal="false" @closed="cancel" width="1200px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-if="form" :disabled="disabled">
        <div class="h c">
          <form-info-item label="时间:">{{new Date(times[0]).format('yyyy/MM/dd')}}--{{new Date(times[1]).format('yyyy/MM/dd')}}</form-info-item>
          <form-info-item label="库房:">{{form.warehouseName}}</form-info-item>
          <div class="flex"></div>
        </div>
        <div class="h c">
          <form-info-item label="编号:">{{form.code}}</form-info-item>
          <form-info-item label="ERP编号:">{{form.erpCode}}</form-info-item>
          <form-info-item label="名称:">{{form.goodsName}}</form-info-item>

          <form-info-item label="品牌:">{{form.brandName}}</form-info-item>
          <form-info-item label="系列:">{{form.seriesName}}</form-info-item>
          <form-info-item label="类型:">{{form.categoryName}}</form-info-item>
          <div class="flex"></div>
        </div>
        <el-form-item prop="items">
          <el-table v-loading="loading" :data="items" class="table-outdent">
            <el-table-column prop="formInfo.formCode" label="单号" min-width="80" />
            <el-table-column prop="formInfo.stocktakingTime" label="盘点时间" min-width="130" :formatter="v=>{return v.formInfo.stocktakingTime?new Date(v.formInfo.stocktakingTime).format('yyyy/MM/dd'):''}" />
            <el-table-column prop="currentCount" label="原库存量" min-width="100" align="center" />
            <el-table-column prop="realCount" label="实际库存量" min-width="100" align="center" />
            <el-table-column label="损益数量" width="120px">
              <template slot-scope="scope">
                <span :style="{ 'color': (scope.row.realCount-scope.row.currentCount)>0 ? '#F56C6C': (scope.row.realCount-scope.row.currentCount)==0?'#000000':'#67C23A' }">{{scope.row.realCount-scope.row.currentCount}}</span>
              </template>
            </el-table-column>

            <el-table-column prop="info" label="备注" min-width="180" align="right" />
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  props: {
    times: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      items: null,
      form: {
        brandId: null,
        brandName: null,
        categoryId: null,
        categoryName: null,
        code: null,
        currentCount: null,
        entId: null,
        erpCode: null,
        goodsId: null,
        goodsName: null,
        id: null,
        inoutCount: null,
        realCount: null,
        seriesId: null,
        seriesName: null,
        specs: null,
        warehouseId: null,
        warehouseName: null
      },
      rules: {}
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        brandId: null,
        brandName: null,
        categoryId: null,
        categoryName: null,
        code: null,
        currentCount: null,
        entId: null,
        erpCode: null,
        goodsId: null,
        goodsName: null,
        id: null,
        inoutCount: null,
        realCount: null,
        seriesId: null,
        seriesName: null,
        specs: null,
        warehouseId: null,
        warehouseName: null
      };
      this.load(form);
    },
    load(val) {
      if (val) {
        let params = {
          begDate: this.times[0],
          endDate: this.times[1],
          warehouseId: val.warehouseId,
          goodsId: val.goodsId
        };
        this.loading = true;
        request({
          url: "api/stocktaking/itemInfo",
          method: "get",
          params
        })
          .then(res => {
            if (res) {
              this.items = res.content;
            }
          })
          .finally(err => {
            this.loading = false;
          });
      }
    }
  }
};
</script>

